import React from 'react'
import { Link } from 'gatsby'

export default () => (

  <section className="beige-bg section-wrapper">
    <div className='container'>
      <div className='four-grid'>
        <h5>Birthday cards</h5>
        <div></div>
        <h5>Special occassion cards</h5>
      </div>
      <div className='four-grid cards-list'>
        <div>
          <ul className='list-unstyled'>
            <li>
              <Link to='/birthday-card'>Video birthday card</Link>
            </li>
            <li>
              <Link to='/lockdown-birthday-card'>Lockdown birthday card</Link>
            </li>
            <li>
              <Link to='/birthday-card-for-friends'>Birthday card for friends</Link>
            </li>
            <li>
              <Link to='/21st-birthday-card'>21st birthday card</Link>
            </li>
            <li>
              <Link to='/30th-birthday-card'>30th birthday card</Link>
            </li>
          </ul>
        </div>

        <div>
          <ul className='list-unstyled'>
            <li>
              <Link to='/40th-birthday-card'>40th birthday card</Link>
            </li>
            <li>
              <Link to='/50th-birthday-card'>50th birthday card</Link>
            </li>
            <li>
              <Link to='/60th-birthday-card'>60th birthday card</Link>
            </li>
            <li>
              <Link to='/70th-birthday-card'>70th birthday card</Link>
            </li>
            <li>
              <Link to='/80th-birthday-card'>80th birthday card</Link>
            </li>
          </ul>
        </div>

        <div>
          <ul className='list-unstyled'>
            <li>
              <Link to='/farewell-cards'>Farewell card</Link>
            </li>
            <li>
              <Link to='/get-well-soon-card'>Get well soon card</Link>
            </li>
            <li>
              <Link to='/anniversary-card'>Anniversary card</Link>
            </li>
            <li>
              <Link to='/wedding-greeting-card'>Wedding greeting card</Link>
            </li>
            <li>
              <Link to='/baby-shower-card'>Baby shower card</Link>
            </li>
            <li>
              <Link to='/mothers-day-card'>Mothers Day card</Link>
            </li>
            <li>
              <Link to='/fathers-day-card'>Fathers Day card</Link>
            </li>
          </ul>
        </div>

        <div>
          <ul className='list-unstyled'>
            <li>
              <Link to='/memorial-group-video-card'>Memorial card &amp; gift</Link>
            </li>
            <li>
              <Link to='/graduation-video-card'>Graduation card</Link>
            </li>
            <li>
              <Link to='/goodbye-cards'>Goodbye cards</Link>
            </li>
            <li>
              <Link to='/boss-day-card'>Boss's day card</Link>
            </li>
            <li>
              <Link to='/thank-you-card-for-teacher'>Thank you card for teacher</Link>
            </li>
            <li>
              <Link to='/christmas-video-card'>Family Christmas card</Link>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </section>
)
