import React from 'react'
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Smilesmall from '../images/smile-small.svg'
import CirclesBg from '../images/circles-bg.svg'
import Header from '../components/header'
import Cards from '../components/cards'
import Footer from '../components/footer'

export default () => (

  <Layout>
    <Helmet>
      <title>Contact Us</title>
      <meta name="description" content="Contact Joycards" />
    </Helmet>
    <Header />
    <section className='sub-page-banner'>
      <div className='container'>
        <div className='two-grid sub-page-head'>
          <div className='sub-left'>
            <h1>Contact Us</h1>
          </div>
          <div className='sub-right'>
            <div className='bg-circles-sub'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
            <div className='smile-img-sub'>
              <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='beige-bg section-wrapper'>
      <div className='container'>
        <div className='sub-page-content'>
          <p>If you have any questions about Joycards, or have experienced technical issues, please send us an email:</p>
          <p>shaun@joy.cards</p>
        </div>
      </div>
    </section>
    <section className='cta'>
      <div className='container flex flex-jc'>
        <div className='cta-btn-wrapper'>
          <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start your card</a>
        </div>
      </div>
    </section>
    <Cards />
    <Footer />
  </Layout>
)
